import styled, { css } from 'styled-components';
import { Box, Chip, Paper, SvgIcon, Typography, Link } from '../elements';

export const SwiperControlsContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 99;
  width: 143px;
  height: 27px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  pointer-events: none;
`;

const cntrlArrow = css`
  top: 0;
  transition: all 0.3s ease;
  position: absolute;
  padding: 5px 4px;
  cursor: pointer;
  opacity: 0;
  z-index: 100;
  pointer-events: all;
`;

export const LeftArrow = styled(SvgIcon).attrs({
  name: 'arrow_left_white',
  size: 24
})`
  left: 0;
  transform: translateX(10px);
  ${cntrlArrow}
`;

export const RightArrow = styled(SvgIcon).attrs({
  name: 'arrow_right_white',
  size: 24
})`
  right: 0;
  transform: translateX(-10px);
  ${cntrlArrow}
`;

export const Card = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  ${({ rounded }) => rounded && css`
    && {
      border-radius: 0.5rem;
      ${({ theme }) => theme.breakpoints.down('sm')} {
        border-radius: 0;
      }
    }
  `}
  &:hover {
    .swiper-pagination {
      padding: 7px 24px !important;
    }
    ${SwiperControlsContainer} {
      display: block;
    }
    ${LeftArrow} {
      transform: none;
      opacity: 1;
    }
    ${RightArrow} {
      transform: none;
      opacity: 1;
    }
  }
  &.card-focused {
    box-shadow: ${({ theme }) => `0px 0px 0px 3px ${theme.palette.primary.main}`};
  }
  ${({ $withbody }) => ($withbody === false) && css`
    && {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
    }
    && > div:nth-child(1) {
      border-radius: 0;
      height: clamp(160px, 25vw, 40vh);
    }
  `}
`;

export const CardImageContainer = styled(Box)`
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 99;
`;

export const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
  ${({ src }) => src && css`
    background-image: ${({ src }) => `url("${src}")`};
    background-position: center center;
    background-size: cover;
  `}
`;

export const CardInfoContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CardPriceInfoContainer = styled(Box)`
  margin-top: auto;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CardIdText = styled(Typography).attrs({
  fontSize: '0.875rem',
  fontWeight: '500'
})``;

export const CardIdBadge = styled(Chip).attrs({
  size: 'small'
})`
  background-color: ${({ theme }) => theme.palette.background.default};
  position: absolute;
  ${({ $idBadgeLocationX }) => $idBadgeLocationX
    ? css`
      ${$idBadgeLocationX}: 1rem;
    `
    : css`
      left: 1rem;
  `};
  z-index: 99;
  ${({ $idBadgeLocation }) => $idBadgeLocation && css`
    ${({ $idBadgeLocation }) => `${$idBadgeLocation}: 1rem;`}
  `};
`;

export const HeaderDialogWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 1rem;
`;

export const OwnerButtons = styled(Link)`
  display: flex;
  padding: .5rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.secondary[35]};
  text-decoration: none;
  border-radius: 6px;
`;

export const ComingSoonContainer = styled(Box).attrs({
  position: 'absolute',
  borderRadius: '0.5rem',
  margin: 0,
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 'fab'
})``;

export const ComingSoonContent = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  padding: '1rem',
  gap: '0.5rem',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(41, 45, 50, 0.2)',
  color: 'secondary.main'
})``;

export const cardPagination = css`
  height: 28px;
  bottom: 1rem !important;
  right: 1rem !important;
  left: auto !important;
  position: absolute !important;
  display: flex !important;
  align-items: center !important;
  padding: 7px 12px !important;
  background: rgba(41, 45, 50, 0.3) !important;
  width: fit-content;
  border-radius: 0.5rem;
  z-index: 99;
  transition: 0.3s all ease;
`;

export const cardPaginationBullet = css`
  margin: 0 4px;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: 50rem;
  padding: 5px;
  transition: 0.3s all ease;
  z-index: 100;
`;

export const cardPaginationBulletActive = css`
  ${cardPaginationBullet}
  padding: 7px;
`;

export const ApartmentContentWrapper = styled(Box).attrs({
  padding: '1rem',
  width: '100%',
  maxWidth: '1000px'
})``;

export const ApartmentNeighbourhood = styled(Box).attrs({
  color: 'primary.70',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1.125rem'
})``;

export const ApartmentStreet = styled(Box).attrs({
  color: 'primary.100',
  fontSize: '1.125rem',
  fontWeight: 700,
  lineHeight: '2.375rem'
})``;

export const ApartmentDetail = styled(Box).attrs({
  color: 'primary.60',
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: '1.125rem'
})``;

export const BestDealContainer = styled(Box).attrs({
  display: 'flex',
  gap: '0.5rem',
  padding: '0.5rem',
  marginTop: '1rem',
  marginBottom: '0.5rem',
  borderRadius: '0.25rem',
  border: '1px solid #85E0A3'
})``;

export const BestDealTitle = styled(Typography).attrs({
  color: 'success.dark',
  fontSize: '0.75rem',
  fontWeight: 600
})``;

export const BestDealContent = styled(Typography).attrs({
  fontSize: '0.75rem',
  fontWeight: 500
})``;

export const BestDealPrice = styled(Typography).attrs({
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.125rem',
  margin: 'auto 0 auto auto'
})``;

export const ModalContainer = styled(Box).attrs({
  width: { xs: '100%', md: 500 },
  py: { xs: 1.5, md: 0 },
  px: { xs: 1, md: 0 }
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DialogButtonTitle = styled(Typography)`
  font-weight: 500;
  font-size: 0.925rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const DialogButtonSubtitle = styled(Typography)`
  font-weight: 400;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const DialogGuestTitle = styled(Typography)`
  font-weight: 600;
  font-weight: 1.125rem;
  line-height: 1.5rem;
`;

export const DialogGuestSubtitle = styled(Typography)`
  font-size: 0.925rem;
  line-height: 1.3rem;
  text-align: center;
`;
