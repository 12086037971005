import React, { useMemo } from 'react';
import {
  Container,
  Box,
  Typography,
  SvgIcon,
  Grid,
  Divider
} from '../elements';
import {
  FooterTitle,
  FooterContent,
  FooterContainer,
  FooterLink,
  FooterSocialLink,
  FooterAppLink
} from './Footer.styles';
import { t } from '../../../js/common/translations';
import { ThemeProvider } from '../../contexts/theme';
import { detectOperatingSystem } from '../../utils/detectOperatingSystem';

const Footer = ({
  socialMediaLinks,
  generalLinks,
  policyLinks,
  gutterBottom = 0,
  googleAppUrl,
  appleAppUrl
}) => {
  const deviceOperatingSystem = useMemo(() => detectOperatingSystem(), []);

  return (
    <ThemeProvider>
      <Box
        component="footer"
        sx={{
          backgroundColor: 'secondary.light',
          pb: gutterBottom
        }}
      >
        <Container
          disableGutters
          maxWidth="xl"
        >
          <Grid
            container
            direction="row"
            sx={{ px: [1.5, 1], pt: 3, pb: [3, 2] }}
          >
            <Grid item md={4} sm={6} xs={12} pb={[2.5, 0]} component="section" className="contact-us">
              <FooterTitle>
                {t('footer.social_media')}
              </FooterTitle>
              <FooterContainer>
                <FooterContent mt={1}>
                  {socialMediaLinks.map(({ href, iconProps }, index) => (
                    <FooterSocialLink
                      mx={0.75}
                      target="_blank"
                      key={index}
                      href={href}
                      rel="nofollow"
                    >
                      <SvgIcon {...iconProps} />
                    </FooterSocialLink>
                  ))}
                </FooterContent>
              </FooterContainer>
            </Grid>
            <Grid item md={2} sm={6} xs={12} pb={[2.5, 0]} >
              <FooterTitle component="h5">
                {t('footer.general_info')}
              </FooterTitle>
              <FooterContainer component="nav" className="general-info" >
                {generalLinks.map(({ href, description, target }, index) => (
                  <FooterLink
                    key={index}
                    href={href}
                    target={target}
                  >
                    <li>{description}</li>
                  </FooterLink>
                ))}
              </FooterContainer>
            </Grid>
            <Grid item md={2} sm={6} xs={12} pb={[2.5, 0]}>
              <FooterTitle component="h5">
                {t('footer.for_owners')}
              </FooterTitle>
              <FooterContainer component="nav" className="for-owners">
                <FooterLink href={'mailto:' + t('contact.property_owner_email')}>
                  <li>{t('contact.property_owner_email')}</li>
                </FooterLink>
                <FooterLink href={'tel:' + t('contact.property_owner_phone')}>
                  <li>{t('contact.property_owner_phone_formatted')}</li>
                </FooterLink>
              </FooterContainer>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <FooterTitle component="h5">
                {t('footer.for_corporate')}
              </FooterTitle>
              <FooterContainer component="nav" className="for-corporate">
                <FooterLink href={'mailto:' + t('contact.corporate_email')}>
                  <li>{t('contact.corporate_email')}</li>
                </FooterLink>
                <FooterLink href={'tel:' + t('contact.corporate_phone')}>
                  <li>{t('contact.corporate_phone_formatted')}</li>
                </FooterLink>
              </FooterContainer>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <FooterTitle component="h5">
                {t('footer.download_app')}
              </FooterTitle>
              <FooterContainer component="nav" className="for-download">
                <Box display="flex" flexDirection={{ lg: 'column' }} gap={0.5}>
                  {deviceOperatingSystem !== 'iOS' && (
                    <FooterAppLink rel="nofollow" target="_blank" href={googleAppUrl}>
                      <SvgIcon name="google_store_large" size="150" />
                    </FooterAppLink>
                  )}
                  {deviceOperatingSystem !== 'android' && (
                    <FooterAppLink rel="nofollow" target="_blank" href={appleAppUrl}>
                      <SvgIcon name="apple_store_large" size="150" />
                    </FooterAppLink>
                  )}
                </Box>
              </FooterContainer>
            </Grid>
          </Grid>
        </Container>
        <Divider light sx={{ opacity: '0.07' }} />
        <Container
          maxWidth="xl"
          disableGutters
        >
          <Grid
            container
            alignItems="center"
            textAlign="center"
            sx={{
              justifyContent: { md: 'space-between' },
              flexDirection: { xs: 'column', md: 'row' },
              p: gutterBottom ? [1, 1, 1, 1, 0] : { xs: 1, xl: 0 },
              pb: gutterBottom ? [5, 5, 1] : 1
            }}
          >
            <Grid item py={0.75} md={2} sm={12} order={{ lg: 2 }}>
              <FooterLink href='/' alt='Tabas logo'>
                <SvgIcon name="logo_default" size="100" alt={t('all_alt.black_logo_tabas')} />
              </FooterLink>
            </Grid>
            <Grid
              item
              md={5}
              sm={12}
              order={{ lg: 3 }}
              sx={{ textAlign: { lg: 'right' }, py: 0.75, display: 'flex', justifyContent: 'flex-end' }}>
              {policyLinks.map(({ href, description }, index) => (
                <FooterLink
                  key={index}
                  href={href}
                  sx={{ px: '1em' }}
                  $policylink
                >
                  <li>{description}</li>
                </FooterLink>
              ))}
            </Grid>
            <Grid item py={0.75} md={5} sm={12} order={{ lg: 1 }} sx={{ textAlign: { lg: 'start' } }}>
              <Typography
                component="span"
                sx={{
                  fontSize: '0.75rem',
                  color: 'primary.dark'
                }}
              >
                {t('footer.copyright')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
