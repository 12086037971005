import React from 'react';
import { Box, Button } from '../elements';
import { Tabs, PropertyCardSlider } from './';

const PropertyTabs = ({ tabs, showTab = true, activeTab = 0, onTabActiveChange }) => {
  return (
    <Tabs
      scroll
      data={tabs.map(tab => ({
        label: tab.label,
        content: (
          <>
            <PropertyCardSlider properties={tab.properties} />
            {tab.action_url && (
              <Box
                sx={{
                  mt: 1.5,
                  width: 1,
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                <Button
                  href={tab.action_url}
                  size="large"
                >
                  {tab.action_label || tab.label}
                </Button>
              </Box>
            )}
          </>
        )
      }))}
      showTab={showTab}
      currentTab={activeTab}
      onTabClick={onTabActiveChange}
    />
  );
};

export default PropertyTabs;
