import React, { useMemo } from 'react';
import { ThemeProvider } from '../../contexts/theme';
import { Toolbar } from '@mui/material';
import { t } from '../../../js/common/translations';
import {
  Box,
  Drawer,
  SvgIcon,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '../elements';
import {
  DividerStyled,
  Greetings,
  ListItemIcon,
  UserName
} from './Sidebar.styles';

const Sidebar = ({ currentMember, currentMemberIsAdmin, active = 'logo_sm_bg_dark' }) => {
  const adminItems = [
    {
      text: 'Backoffice',
      path: t('logged_user_navigation.backoffice_path'),
      icon: 'desktop'
    }
  ];

  const memberItems = [
    {
      text: t('bookings_page.header_title'),
      path: t('logged_user_navigation.bookings_path'),
      icon: 'logo_sm_bg_dark'
    },
    {
      text: t('logged_user_navigation.invites'),
      path: t('logged_user_navigation.invites_path'),
      icon: 'share_booking'
    },
    {
      text: t('logged_user_navigation.profile_link'),
      path: t('logged_user_navigation.profile_link_path'),
      icon: 'logged_user'
    },
    {
      text: t('logged_user_navigation.messages'),
      path: t('logged_user_navigation.messages_path'),
      icon: 'message'
    },
    {
      text: t('logged_user_navigation.services'),
      path: t('logged_user_navigation.services_path'),
      icon: 'cart'
    },
    {
      text: 'Log Out',
      path: t('logged_user_navigation.destroy_session'),
      icon: 'logout'
    }
  ];

  const menuItems = useMemo(() => {
    if (currentMemberIsAdmin) {
      return [...adminItems, ...memberItems];
    } else {
      return memberItems;
    }
  }, [currentMemberIsAdmin, adminItems, memberItems]);

  const drawerWidth = 216;

  return (
    <ThemeProvider>
      <Drawer
        PaperProps={{ component: 'aside' }}
        sx={{
          position: 'sticky',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: 'background.100',
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <Toolbar />
          <Box px="1rem" pb="1.5rem">
            <Greetings>{t('sidebar.greetings')}</Greetings>
            <UserName>{currentMember.fullname}</UserName>
          </Box>
          {menuItems.map((item) => (
            <React.Fragment key={item.text}>
              <Link href={item.path} noDecoration>
                <ListItem disablePadding sx={{ opacity: '0.8' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <SvgIcon name={item.icon} size="18" />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      sx={{
                        ...(item.icon === active && {
                          '& span': {
                            fontWeight: '600'
                          }
                        })
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
              {item.icon !== 'logout' && <DividerStyled />}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
