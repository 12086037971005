import React from 'react';
import { Container, Fade, Button, Typography, Box, Link } from '../elements';
import { ThemeProvider } from '../../contexts/theme';
import { useBreakpoint } from '../../hooks/useBreakpoint';

const CTABottomBar = ({
  actionButtonProps,
  text,
  visible = true,
  sx: barSx = {},
  ...rest
}) => {
  const currentBreakpoint = useBreakpoint();
  const { sx: actionButtonSx = {}, ...actionButtonPropsRest } = actionButtonProps;

  return (
    <ThemeProvider>
      <Fade in={visible}>
        <Link
          href={actionButtonProps.href && actionButtonProps.href}
          onClick={actionButtonProps.onClick && actionButtonProps.onClick}
          target={actionButtonProps.target && actionButtonProps.target}
        >
          <Box
            sx={{
              py: [1, 0],
              width: 1,
              zIndex: 1100,
              position: 'fixed',
              bottom: 0,
              boxShadow: ['0px -2px 8px rgba(96, 97, 112, 0.16)', 'none'],
              bgcolor: ['background.default', 'transparent']
            }}
          >
            <Box
              sx={{
                p: [0, 0.75],
                alignItems: 'center',
                bgcolor: ['transparent', 'rgba(41, 45, 50, 0.85)'],
                justifyContent: ['center', 'normal'],
                ...barSx
              }}
              {...rest}
            >
              <Container
                maxWidth="xl"
                disableGutters
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  px: 4
                }}
              >
                <Button
                  variant="contained"
                  color={currentBreakpoint === 'xs'
                    ? 'primary'
                    : 'secondary'
                  }
                  size="large"
                  sx={{
                    width: [1, 'auto'],
                    height: '100% !important',
                    ...actionButtonSx
                  }}
                  {...actionButtonPropsRest}
                />
                <Box sx={{ px: 2, display: ['none', 'inline'] }}>
                  <Typography
                    color="secondary"
                    sx={{
                      fontWeigth: 500,
                      typography: ['h6', 'h5', 'h4']
                    }}>
                    {text}
                  </Typography>
                </Box>
              </Container>
            </Box>
          </Box>
        </Link>
      </Fade>
    </ThemeProvider>
  );
};

export default CTABottomBar;
