import React from 'react';
import styled, { css } from 'styled-components';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions
} from '@mui/material';
import SvgIcon from './SvgIcon';
import Box from './Box';

const Dialog = ({
  children,
  headerContent,
  headerProps = {},
  contentProps = {},
  actionsProps = {},
  actions,
  minwidth600,
  themeType = 'light',
  titleAlign = 'center',
  overflowContent = 'auto',
  showCloseIcon = true,
  ...rest
}) => {
  return (
    <CustomMuiDialog minwidth600={minwidth600} $themeType={themeType} {...rest}>
      {(headerContent || rest.onClose) && (
        <DialogHeader {...headerProps} component="div">
          <HeaderContentWrapper $titleAlign={titleAlign}>
            {headerContent}
          </HeaderContentWrapper>
          {showCloseIcon && (
            <HeaderCloseIconWrapper>
              {themeType === 'light' ? (
                <SvgIcon name="close_icon" onClick={rest.onClose} />
              ) : (
                <SvgIcon name="close_icon_black" onClick={rest.onClose} />
              )}
            </HeaderCloseIconWrapper>
          )}
        </DialogHeader>
      )}
      <DialogContent $overflowContent={overflowContent} {...contentProps}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions {...actionsProps}>
          {actions}
        </DialogActions>
      )}
    </CustomMuiDialog>
  );
};

const CustomMuiDialog = styled(MuiDialog)`
  .MuiBackdrop-root {
    background-color: rgba(41, 45, 50, 0.3)
  }

  .MuiDialog-paper {
    border-radius: 12px;
    ${({ $themeType }) => ($themeType === 'dark') && css`
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.secondary.main};
    `}

    ${({ minwidth600 }) => minwidth600 && css`
      min-width: 650px
    `}
  }
`;

const DialogContent = styled(MuiDialogContent)`
  ${({ $overflowContent }) => $overflowContent && css`
    overflow-y: ${$overflowContent};
  `}
`;

const DialogHeader = styled(DialogTitle)`
  display: flex;
  flex-direction: row;
`;

const HeaderContentWrapper = styled(Box)`
  display: flex;
  width: 100%;
  ${({ $titleAlign }) => $titleAlign && css`
    justify-content: ${$titleAlign};
  `}
`;

const HeaderCloseIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default Dialog;
