import React from 'react';
import { t } from '../../../js/common/translations';
import { Dialog, Divider, SvgIcon, Typography } from '../elements';
import { ContentWrapper, ModalLink } from './ContactUsDialog.styles';

const ContactUsDialog = ({
  onClose,
  open,
  props,
  maxWidth,
  whatsappContactLink,
  ...rest
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      onClose={onClose}
      sx={{ width: '100%' }}
      open={open}
      headerContent={(
        <ContentWrapper px={4} my={0} gap={0}>
          <SvgIcon name="logo_default" size="184" />
          <Typography my={0.25} color="primary.70" variant="lead">{t('footer.slogan')}</Typography>
        </ContentWrapper>
      )}
      {...rest}
    >
      <Divider />
      <ContentWrapper gap={1} my={1} >
        <Typography>{t('footer.contact_us')}</Typography>
        <ModalLink
          href={props.whatsappRedirectPathHref}
          target="_blank"
          rel="nofollow"
          className="whatsapp-link whatsapp-link-content-contact-us"
        >
          <SvgIcon mr={0.5} name="whatsapp" />
          {t('contact.whatsapp_formatted')}
        </ModalLink>
        <ModalLink href={`tel: ${t('contact.phone')}`}>
          <SvgIcon mr={0.5} name="phone_dark" />
          {t('contact.phone_formatted')}
        </ModalLink>
        <ModalLink href={`mailto: ${t('contact.booking_email')}`}>{t('contact.booking_email')}</ModalLink>
      </ContentWrapper>
      <Divider />
      <ContentWrapper gap={1} my={1}>
        <Typography>{t('footer.for_corporate')}</Typography>
        <ModalLink href={`mailto: ${t('contact.corporate_email')}`}>
          {t('contact.corporate_email')}
        </ModalLink>
        <ModalLink href={`tel: ${t('contact.corporate_phone')}`}>
          {t('contact.corporate_phone_formatted')}
        </ModalLink>
      </ContentWrapper>
    </Dialog>
  );
};

export default ContactUsDialog;
