import React from 'react';
import styled from 'styled-components';
import { SvgIcon, BottomBar, Paper } from '../elements';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ThemeProvider } from '../../contexts/theme';

const BottomStickyMenu = ({ links, currentMember, currentMemberIsAdmin }) => {
  return (
    <ThemeProvider>
      <BottomBarContainer elevation={2}>
        <BottomBar showLabels>
          {links.map(({ href, name, description, islogged = false, fixed = false }) => {
            const isPage = window.location.href.toString().includes(href);
            return (fixed || !!currentMember === islogged) &&
              <StyledBottomNavigationAction
                sx={{
                  maxWidth: 'fit-content',
                  fontWeight: isPage ? 'bold' : 'normal',
                  '& .MuiBottomNavigationAction-label': {
                    marginTop: '0.10rem'
                  }
                }}
                href={href}
                key={name}
                label={description}
                icon={<SvgIcon name={name} size={19} />}
              />;
          })}
        </BottomBar>
      </BottomBarContainer>
    </ThemeProvider>
  );
};

const BottomBarContainer = styled(Paper)`
  a:hover {
    color: #555656;
  }
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  right: 0;
  z-index: 101;
  box-shadow: 1px 0px 3px ${({ theme }) => theme.palette.primary[10]};
  height: 56px;
  padding: 8px 32px 8px 32px;
  button {
    outline: none;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    span {
      font-size: 0.65rem;
    }
    white-space: nowrap;
    width: 40px;
  }
  padding: 0;
  min-width: 70px;
`;

export default BottomStickyMenu;
